import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { artworkStateSelector } from "./artworkReducer";

export function useTokenId() {
    const router = useRouter();
    const artworkState = useSelector(artworkStateSelector);

    return Number(artworkState.tokenId || router.query.tokenId);
}
