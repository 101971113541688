import { useDispatch } from "react-redux";
import { useEffect } from "react";
import noop from "lodash/noop";
import { useContractAddress } from "~/features/artwork/useContractAddress";
import { subscribeToTokenStateMachine } from "~/api/pusher/pusherClient";
import {
    getTokenRights,
    loadTokenMarketData,
    loadToken,
} from "~/api/choreography";
import { useTokenId } from "~/features/artwork/useTokenId";
import { useTokenParams } from "~/features/artwork/useTokenParams";

export function TokenLockSubscription() {
    const contractAddress = useContractAddress();
    const dispatch = useDispatch();
    const tokenId = useTokenId();
    const { blockchainId } = useTokenParams();

    useEffect(() => {
        if (contractAddress) {
            const subscription = subscribeToTokenStateMachine(
                +tokenId,
                contractAddress,
                () => {
                    dispatch(loadTokenMarketData({ tokenId: +tokenId }));
                    dispatch(getTokenRights(+tokenId));
                    if (tokenId && blockchainId) {
                        dispatch(
                            loadToken({
                                tokenId,
                                networkId: Number(blockchainId),
                                tokenContractAddress: contractAddress,
                            }),
                        );
                    }
                },
            );

            return () => subscription.unsubscribe();
        }

        return noop;
    }, [dispatch, contractAddress, tokenId, blockchainId]);

    return null;
}
