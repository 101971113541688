import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";

export function getBidsChannelName(
    networkId: number,
    auctionId: number,
    tokenId: number,
    application: SupportedApplication,
) {
    return `bids_${networkId}_${auctionId}_${tokenId}_${application}`;
}
export function getBidsResponseChannelName(
    networkId: number,
    auctionId: number,
    tokenId: number,
    application: SupportedApplication,
    reqId: string,
) {
    return `bids_${networkId}_${auctionId}_${tokenId}_${application}_${reqId}`;
}
export function getTokenStateMachineChannelName(
    tokenId: number,
    tokenContractAddress: string,
) {
    return `token_state_machine_${tokenId}_${tokenContractAddress}`;
}
