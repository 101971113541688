import Pusher from "pusher-js";
import memoize from "lodash/memoize";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { PusherEventName } from "./eventName";
import {
    getBidsChannelName,
    getBidsResponseChannelName,
    getTokenStateMachineChannelName,
} from "./channelName";
import { BeBid } from "../data-schema";

const instantiatePusher = memoize(
    () =>
        new Pusher(process.env.NEXT_PUBLIC_PUSHER_APP_KEY as string, {
            cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER as string,
        }),
    () => 1,
);

export function subscribeToNewBidsChannel(
    networkId: number,
    auctionId: number,
    tokenId: number,
    application: SupportedApplication,
    onBid: (bid: BeBid) => void,
) {
    const pusher = instantiatePusher();

    const channelName = getBidsChannelName(
        networkId,
        auctionId,
        tokenId,
        application,
    );
    const channel = pusher.subscribe(channelName);
    channel.bind(PusherEventName.BID_CREATED, onBid);

    return {
        unsubscribe() {
            pusher.unsubscribe(channelName);
        },
    };
}
export function subscribeToBidResponseChannel(
    networkId: number,
    auctionId: number,
    tokenId: number,
    application: SupportedApplication,
    reqId: string,
    onResponse: (response: {
        success: boolean;
        message: string;
        isCash: boolean;
    }) => void,
) {
    const pusher = instantiatePusher();

    const channelName = getBidsResponseChannelName(
        networkId,
        auctionId,
        tokenId,
        application,
        reqId,
    );

    const channel = pusher.subscribe(channelName);
    channel.bind(PusherEventName.BID_RESPONSE, onResponse);

    return {
        unsubscribe() {
            pusher.unsubscribe(channelName);
        },
    };
}
export function subscribeToTokenStateMachine(
    tokenId: number,
    tokenContractAddress: string,
    onLock,
) {
    const pusher = instantiatePusher();
    const channelName = getTokenStateMachineChannelName(
        tokenId,
        tokenContractAddress,
    );
    const channel = pusher.subscribe(channelName);
    channel.bind(PusherEventName.TOKEN_LOCKED, onLock);

    return {
        unsubscribe() {
            pusher.unsubscribe(channelName);
        },
    };
}
